<template>
  <div class="school-introduction">
    <div class="row">
      <div class="col-md-6">
        <h2 class="mt-3">学校简介</h2>
        <p>龙川县田家炳中学创办于2001年，由香港著名实业家田家炳先生捐资建设。作为广东省河源市的一所重点中学，学校拥有优越的教学设施和环境，致力于培养德智体美劳全面发展的学生。</p>
        <p>学校不仅重视学生的学术成绩，还强调学生的综合素质培养。学校开设了丰富的课外活动和社团，包括科技创新、文艺表演、体育竞技等，旨在全面提升学生的综合素养。此外，学校还积极开展各类社会实践活动，鼓励学生参与社区服务和志愿者工作，以培养学生的社会责任感和实践能力。</p>
      </div>
      <div class="col-md-6">
        <img src="https://krseoul.imgtbl.com/i/2024/07/14/6693d9f09a920.png" class="img-fluid rounded" alt="学校图片">
      </div>
    </div>
  </div>
</template>

<style scoped>
.school-introduction {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.school-introduction h2 {
  color: #2c3e50;
}
.school-introduction p {
  color: #34495e;
}
</style>
