<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">田家炳中学</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#">首页</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">关于我们</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">招生信息</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">联系我们</a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container mt-5">
      <SchoolIntroduction />
      <VideoSection />
    </div>
  </div>
</template>

<script>
import SchoolIntroduction from './components/SchoolIntroduction.vue';
import VideoSection from './components/VideoSection.vue';

export default {
  name: 'App',
  components: {
    SchoolIntroduction,
    VideoSection
  },
  mounted() {
    if (!this.$root.audioPlayed) {
      const audio = new Audio('/audio/welcome.mp3');
      audio.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
      this.$root.audioPlayed = true;
    }
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  color: #333;
}
</style>
