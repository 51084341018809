<template>
  <div class="home">
    <h1>欢迎来到龙川田家炳中学</h1>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
};
</script>

<style scoped>
.home {
  padding: 20px;
}
</style>
