<template>
  <div class="video-section mt-5">
    <h2 class="mb-3">欢迎观看我们的招生视频</h2>
    <div class="embed-responsive embed-responsive-16by9">
      <video class="embed-responsive-item" controls>
        <source src="@/assets/video/promo.mp4" type="video/mp4">
        您的浏览器不支持 HTML5 视频。
      </video>
    </div>
  </div>
</template>

<style scoped>
.video-section {
  text-align: center;
}
.video-section h2 {
  color: #2c3e50;
}
</style>
